import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

// css
import "./hero-section.css"

// images
import HomeBannerImage from "../../assets/images/home/landing-row-bg.png"
import HomeBannerImage3 from "../../assets/images/home/home-slide-3.png"
import playButton from "../../assets/images/play-button.svg"
import ProbeInfoPanel from "../../components/probe-info-ar"

const HeroSection = ({}) => {
  const requiredDateTime = "2020-07-20T01:58:14+04:00"
  const [showCountdown, setShowCountdown] = useState(true)

  const CheckTime = () => {
    const currentTime = new Date()
    const required = new Date(requiredDateTime)

    if (currentTime.getTime() >= required.getTime()) {
      setShowCountdown(false)
    } else {
        setTimeout(CheckTime, 500)
    }
  }

  useEffect(() => {
    CheckTime()
  }, [])

  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light"
      data-tabindex="-1"
      data-uk-slider="center: true; autoplay: true;"
      style={{
        backgroundImage: `url(${HomeBannerImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <ul
        className="uk-slider-items uk-grid uk-grid-match"
        data-uk-height-viewport
      >
        <li className="uk-width-1-1">
          <div className="uk-cover-container">
            <img
              src={HomeBannerImage}
              alt=""
              style={{
                transform: " translate(50%,-50%) scaleX(-1)",
                WebkitTransform: " translate(50%,-50%) scaleX(-1)",
                filter: "FlipV",
              }}
              data-uk-cover
            />
            <div
              className="uk-position-left uk-position-center uk-panel ph5 hero-text-container"
              style={{ transform: "translate(50%,-50%)" }}
            >
              <h1 className="uk-heading-medium@s uk-heading-small uk-visible@s">
                مشروع الإمارات لاستكشاف <br />
                المريخ “مسبار الأمل”
              </h1>
              <h2 className=" uk-hidden@s">
                مشروع الإمارات لاستكشاف <br />
                المريخ “مسبار الأمل”
              </h2>
              <div className="heading-underline-light"></div>
              <p className="mv4 text-light">
                أول مهمة عربية إلى كوكب آخر
              </p>
              <Link to="/ar/mission/about-emm">
                <span className="uk-text-bold">تعرف على المزيد</span>
              </Link>
            </div>
          </div>
        </li>
        <li className="uk-width-1-1">
          <div className="uk-cover-container">
            <video
              src="https://emm-f548.kxcdn.com/home-slider.mp4"
              autoplay="1"
              playsinline="1"
              muted="1"
              loop="1"
              data-uk-cover
              style={{ transform: "translate(50%,-50%)" }}
            ></video>
            <div
              className="uk-position-left uk-position-center uk-panel mh4 hero-text-container"
              style={{ transform: "translate(50%,-50%)" }}
            ></div>
          </div>
        </li>
        <li className="uk-width-1-1 overflow-x-hidden">
          <img
            src={HomeBannerImage3}
            className="home-slider-custom-image-ar"
            alt=""
            style={{ transform: "translate(50%,-50%)" }}
            data-uk-cover
          />

          <div
            className="uk-position-cover"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.29)" }}
          ></div>
          <div
            className="uk-position-left uk-position-center uk-panel ph5 hero-text-container"
            style={{ transform: "translate(50%,-50%)" }}
          >
            <h1 className="uk-heading-medium@s uk-heading-small uk-visible@s">
              سفراء الأمل
            </h1>
            <h2 className="uk-hidden@s">سفراء الأمل</h2>
            <div className="heading-underline-light"></div>
            <div className="mv4"></div>
          </div>
        </li>
      </ul>
      <div
        className="flex flex-column mb6-ns uk-position-bottom uk-panel"
        style={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", padding: "20px 0px 20px 0px", margin: "auto" }}
      >
        <div className="mv4 ph4-ns">
          <div>
            <ul
              className="uk-slider-nav uk-flex-center uk-margin custom-dot-nav"
            >
              <li uk-slider-item="0">
                <a href="#">01</a>
              </li>
              <li uk-slider-item="1">
                <a href="#">02</a>
              </li>
              <li uk-slider-item="2">
                <a href="#">03</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="w-100 flex justify-end">
          <Link
            to="/ar/live"
            className="pv3 pl3 pr4 ml5-ns uk-text-bolder"
            style={{
              backgroundColor: "var(--highlight-color)",
              color: "#fff",
              borderRadius: "10px 50px 0px 0px",
            }}
          >
            شاهد عملية الإطلاق
            <img src={playButton} className="mr2" alt="" />
          </Link>
        </div> */}

        {/* <ProbeInfoPanel /> */}
      </div>
    </div>
  )
}

export default HeroSection
